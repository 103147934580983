<template>
  <div class="contain">
    <div class="header-title">
      <!-- <span>{{ pageTitle }}</span>
      <el-button class="return-button" @click="onCancel" v-if="type != 'view'">返回</el-button> -->
      <el-page-header @back="onCancel" :content="pageTitle">
        <template slot="title">
          <i class="el-icon-arrow-left"></i><span>返回</span>
        </template>
      </el-page-header>
    </div>
    <div class="form">
      <div class="form-share-title">
        <span>园校名称：</span><span>{{ schoolName }}</span>
      </div>
      <div class="form-share-title"><span>督导任务：</span><span></span></div>
      <div class="view-body">
        <div class="view-body-title">{{ taskInfo.templateName }}</div>
        <div class="view-body-remark">{{ taskInfo.description }}</div>
        <div class="dashed-line"></div>
        <div class="view-body-content-choose">
          <div class="label">督导内容：</div>
          <div class="item">
            <el-select
              v-model="projectContent"
              placeholder="请选择"
              @change="handleSelectSupervise"
            >
              <el-option
                v-for="item in projectContentOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="view-body-content-list">
          <div
            class="view-body-content-item"
            v-for="(item, index) in projectContentList"
            :key="item.id"
          >
            <div class="item-title">{{ item.projectName }}</div>
            <div
              class="item-options"
              v-for="(info, i) in item.optionsList"
              :key="info.id"
            >
              <div class="options-question">
                {{ info.optionsNum }}、{{ info.projectName }}
              </div>
              <el-radio-group
                class="options-list"
                v-model="info.chooseOptions"
                :disabled="true"
              >
                <el-radio
                  class="options-item choose"
                  :label="optionInfo.id"
                  v-for="optionInfo in info.options"
                  :key="optionInfo.id"
                  ><span class="text">{{ optionInfo.projectName }}</span
                  ><span class="score">{{ optionInfo.score }}分</span></el-radio
                >
              </el-radio-group>
              <div class="options-score-list" v-show="info.isShowScoreList">
                <div
                  class="score-text"
                  @change="handleScoreShow(info, i, index)"
                >
                  <span>历史评分</span
                  ><img
                    :class="{ changeArraw: info.isChangeShow }"
                    :src="arrowIcon"
                    alt=""
                  />
                </div>
                <l-table
                  v-show="!info.isChangeShow"
                  :columns="columns"
                  :dataSource="info.supOrderTaskRecordList"
                  :pagination="false"
                  :showXh="true"
                  :height="'calc(100% - 2.91667vw)'"
                  :autoHeight="false"
                >
                  <el-table-column
                    prop="createTime"
                    label="时间"
                    align="center"
                    width="120"
                    slot="createTime"
                  ></el-table-column>
                  <el-table-column
                    prop="nodeName"
                    label="操作"
                    align="center"
                    width="150"
                    slot="nodeName"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.nodeName == "1"
                          ? "园校评分"
                          : scope.row.nodeName == "2"
                          ? "分公司评分"
                          : scope.row.nodeName == "3"
                          ? "总部评分"
                          : ""
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="operate"
                    label="操作者"
                    align="center"
                    width="150"
                    slot="operate"
                  ></el-table-column>
                  <el-table-column
                    prop="score"
                    label="评分"
                    align="center"
                    width="100"
                    slot="score"
                  ></el-table-column>
                  <el-table-column
                    prop="comment"
                    label="评分意见"
                    align="center"
                    min-width="300"
                    slot="comment"
                  ></el-table-column>
                  <el-table-column
                    prop="attachmentList"
                    label="附件"
                    align="center"
                    min-width="250"
                    slot="attachmentList"
                  >
                    <template slot-scope="scope">
                      <el-button
                        class="table-handle file"
                        @click="downloadScoreFile(item)"
                        type="text"
                        v-for="item in scope.row.attachmentList"
                        :key="item.attachmentId"
                        >{{ item.attachmentName }}</el-button
                      >
                    </template>
                  </el-table-column>
                </l-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-image
      style="width: 0; height: 0"
      ref="viewImg"
      :src="viewUrl"
      :preview-src-list="viewUrlList"
    ></el-image>
    <onlinePreview
      :onlinePreviewVisible="onlinePreviewVisible"
      :type="fileAttachment.previewType"
      @closeOnlinePreviewDialog="closeOnlinePreviewDialog"
      :url="fileAttachment.url"
      :title="fileAttachment.title"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import arrowIcon from "@/assets/img/arrow-icon.png";
import LTable from "@/components/tableComponent/index.vue";
import downloadFile from "@/utils/downloadFile.js";
import onlinePreview from "@/components/onlinePreview/index.vue";
export default {
  name: "taskShareDetail",
  components: { LTable, onlinePreview },
  data() {
    return {
      type: "", //编辑类型
      jumpType: "", //跳转类型
      id: null, //待办任务id
      pageTitle: "查看分享", //页面标题

      superviseTypeOptions: [
        {
          name: "远程督导",
          value: "1",
        },
        {
          name: "现场督导",
          value: "2",
        },
      ], //督导方式下拉

      taskInfo: {}, //模板信息
      userInfo: {}, //登录人信息
      projectContent: "", //督导内容下拉选中项
      projectContentOptions: [], //督导内容下拉list
      projectContentList: [], //督导内容与选项list
      requestData: [], //原始请求数据

      buttonLoading: false, //按钮loading

      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "时间",
          slotName: "createTime",
        },
        {
          label: "操作",
          slotName: "nodeName",
        },
        {
          label: "操作者",
          slotName: "operate",
        },
        {
          label: "评分",
          slotName: "score",
        },
        {
          label: "评分意见",
          slotName: "comment",
        },
        {
          label: "附件",
          slotName: "attachmentList",
        },
      ],
      errorTableData: [], //表单数据

      schoolName: "",

      onlinePreviewVisible: false, //文件预览标识
      fileAttachment: {}, //预览文件对象

      viewUrl: "", // 附件图片预览
      viewUrlList: [], // 附件图片预览list

      fullPageLoading: false, //整体页面loading

      arrowIcon, //箭头icon
    };
  },
  computed: {
    ...mapState({
      fileTypeList: (state) => state.common.fileTypeList,
      postOptions: (state) => state.common.postList,
    }),
  },
  //路由进入前改变title
  beforeRouterEnter(to, from, next) {
    if (to.query.jumpType == "company") {
      to.meta.title = "分公司查看分享";
    } else if (to.query.jumpType == "school") {
      to.meta.title = "园校查看分享";
    } else {
      to.meta.title = "总部查看分享";
    }
    next();
  },
  async created() {
    this.id = this.$route.query.id;
    this.jumpType = this.$route.query.jumpType;
    let userInfo = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : {};
    this.userInfo = userInfo;
    this.schoolName =
      userInfo.schoolList && userInfo.schoolList.length > 0
        ? userInfo.schoolList[0].schoolName
        : "";

    await this.getSuperviseSelection();
    this.getTaskScoreInfo();
  },
  methods: {
    //获取督导内容下拉
    async getSuperviseSelection() {
      await this.$api
        .getAgencyTaskScoreSuperviseInfo(this.id)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data) {
              // res.data.data.map((item) => {
              //   item.isFinish = false
              // })
              let list = []
                .concat([{ id: "", name: "全部", isFinish: false }])
                .concat(res.data.data);
              this.projectContentOptions = list;
            } else {
              this.projectContentOptions = [];
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    //获取任务评分信息
    getTaskScoreInfo(id) {
      this.$api
        .getAgencyTaskScoreInfo(id ? id : this.id,{})
        .then((res) => {
          if (res.data.code == 0) {
            let resultInfo = res.data.data;
            this.taskInfo = {
              templateId: resultInfo.templateId,
              templateName: resultInfo.templateName,
              description: resultInfo.description,
              orderStatus: resultInfo.orderStatus,
            };
            resultInfo.oneLevelList.map((item, index) => {
              item.firstLevelIndex = index;
              if (item.fourLevelProject && item.fourLevelProject.length > 0) {
                item.fourLevelProject.map((info, i) => {
                  info.firstLevelIndex = index;
                  info.fourLevelIndex = i;
                  if (info.options && info.options.length > 0) {
                    info.options.map((optionInfo, j) => {
                      optionInfo.firstLevelIndex = index;
                      optionInfo.fourLevelIndex = i;
                      optionInfo.optionIndex = j;
                    });
                  }
                });
              }
            });
            let list = JSON.parse(JSON.stringify(resultInfo.oneLevelList));
            let topicNum = 1;
            list.map((item) => {
              if (item.fourLevelProject && item.fourLevelProject.length > 0) {
                item.fourLevelProject.map((info) => {
                  info.optionsNum = topicNum;
                  info.isShowScoreList = true;
                  info.isRectify = info.isRectify
                    ? info.isRectify == "1"
                      ? true
                      : false
                    : false;
                  info.chooseRectify = false;
                  info.isLibrary = info.isLibrary
                    ? info.isLibrary == "1"
                      ? true
                      : false
                    : false;
                  info.isTotalScore = false;
                  info.commentVisible = false;
                  info.isChangeShow = false;
                  info.chooseOptions = "";
                  info.selectOptionsScore = "";
                  let uploadTipString = [];
                  if (info.attachType) {
                    let attachTypeList = info.attachType
                      .split(",")
                      .filter((info) => {
                        return Number(info) > 5;
                      });
                    let isFileType = "";
                    let isVideoType = "";
                    if (info.attachType.indexOf("2") != -1) {
                      isFileType = "2";
                    } else if (
                      info.attachType.indexOf("1") != -1 &&
                      info.attachType.indexOf("2") == -1
                    ) {
                      isFileType = "1";
                    }
                    if (info.attachType.indexOf("5") != -1) {
                      isVideoType = "5";
                    } else if (
                      info.attachType.indexOf("4") != -1 &&
                      info.attachType.indexOf("5") == -1
                    ) {
                      isVideoType = "4";
                    } else if (
                      info.attachType.indexOf("3") != -1 &&
                      info.attachType.indexOf("4") == -1 &&
                      info.attachType.indexOf("5") == -1
                    ) {
                      isVideoType = "3";
                    }
                    if (isFileType) {
                      attachTypeList.push(isFileType);
                    }
                    if (isVideoType) {
                      attachTypeList.push(isVideoType);
                    }
                    if (attachTypeList.length > 0) {
                      attachTypeList.map((attachTypeInfo) => {
                        let attachInfo = this.fileTypeList.find((file) => {
                          return file.value == attachTypeInfo;
                        });
                        console.log(attachInfo);
                        if (attachInfo) {
                          uploadTipString.push(attachInfo.name);
                        }
                      });
                      info.fileTip =
                        "上传文件限制为：" + uploadTipString.join(",") + "。";
                      info.fileTypeList = attachTypeList;
                    } else {
                      info.fileTip = "";
                      info.fileTypeList = [];
                    }
                  }
                  topicNum++;
                  if (info.options && info.options.length > 0) {
                    let chooseInfo = {};
                    info.options.map((optionInfo) => {
                      optionInfo.projectName =
                        optionInfo.projectName.split(" ")[0];
                      if (optionInfo.isCheck === "1") {
                        info.chooseOptions = optionInfo.id;
                        info.selectOptionsScore = optionInfo.score;
                        chooseInfo = optionInfo;
                      }
                    });
                    info.maxScore = Number(info.score);
                    if (
                      chooseInfo.score &&
                      Number(chooseInfo.score) === Number(info.score)
                    ) {
                      info.isTotalScore = true;
                    }
                  }
                });
              }
              item.optionsList = item.fourLevelProject;
              let flag = item.fourLevelProject.every((optionsInfo) => {
                return optionsInfo.chooseOptions !== "";
              });
              // if(flag){
              //   let chooseIndex = this.projectContentOptions.findIndex((chooseInfo) => { return chooseInfo.id == item.id })
              //   if(chooseIndex != -1){
              //     let info = JSON.parse(JSON.stringify(this.projectContentOptions[chooseIndex]))
              //     info.isFinish = true
              //     this.$set(this.projectContentOptions,chooseIndex,info)
              //   }
              // }
            });
            this.requestData = JSON.parse(JSON.stringify(list));
            // if(resultInfo.orderStatus && resultInfo.orderStatus !== '1' && resultInfo.orderStatus !== '2' && resultInfo.orderStatus !== '3'){
            //   this.getShowData()
            // } else {
            //   this.projectContentList = list
            // }
            this.projectContentList = list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    //改变督导内容
    handleSelectSupervise(val) {
      this.projectContent = val;
      this.getShowData();
    },
    //历史评分展开收起
    handleScoreShow(optionInfo, optionIndex, listIndex) {
      let resultInfo = JSON.parse(JSON.stringify(optionInfo));
      resultInfo.isChangeShow = !resultInfo.isChangeShow;
      this.$set(
        this.projectContentList[listIndex].optionsList,
        optionIndex,
        resultInfo
      );
      this.$set(
        this.requestData[resultInfo.firstLevelIndex].optionsList,
        resultInfo.fourLevelIndex,
        resultInfo
      );
    },
    //关闭预览
    closeOnlinePreviewDialog() {
      this.fileAttachment = {};
      this.onlinePreviewVisible = false;
    },
    //获取整改项数据
    getShowData() {
      let list = [];
      if (this.projectContent) {
        this.requestData.map((item) => {
          if (item.id == this.projectContent) {
            list.push(item);
          }
        });
      } else {
        list = JSON.parse(JSON.stringify(this.requestData));
      }

      this.projectContentList = list;
    },
    //下载历史评分附件
    downloadScoreFile(row) {
      let url = "/supervise/attachment/downloadFile";
      let noDownloadIndex = [
        "png",
        "jpg",
        "jpeg",
        "gif",
        "mp4",
        "mov",
        "rmvb",
        "flv",
        "avi",
        "mpg",
      ].findIndex((info) => {
        return info == row.attachmentType;
      });
      let index = ["png", "jpg", "jpeg", "gif"].findIndex((info) => {
        return info == row.attachmentType;
      });
      let videoIndex = ["mp4", "mov", "rmvb", "flv", "avi", "mpg"].findIndex(
        (info) => {
          return info == row.attachmentType;
        }
      );
      if (row.path && noDownloadIndex === -1) {
        let data = {
          id: row.attachmentId,
          type: 1,
          flag: 0,
        };
        let type = "get";
        downloadFile({ url, data, type, prevent: false });
      } else if (videoIndex != -1) {
        this.onlinePreviewVisible = true;
        this.$nextTick(() => {
          this.fileAttachment = {
            previewType: "video",
            url: row.path,
            attachment_name: row.attachmentName,
            attachment_type: row.attachmentType,
            title: row.attachmentName,
          };
        });
      } else if (index != -1) {
        this.viewUrl = row.path;
        this.viewUrlList = [row.path];
        this.$nextTick(() => {
          this.$refs.viewImg.showViewer = true;
        });
      } else {
        this.$message.error("视频和图片不支持下载");
      }
    },
    //取消
    onCancel() {
      this.$router.back(-1);
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.contain {
  width: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .header-title {
    width: 100%;
    color: #3d3e4f;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .return-button {
      &.el-button:focus {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
      &.el-button:hover {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
    }
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #003685;
    background: #003685;
  }

  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #003685;
  }

  /deep/.el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }
  .dashed-line {
    width: 100%;
    height: 0;
    border-bottom: 1px dashed #e2e7f0;
    margin: 40px 0;
  }
  .form {
    width: calc(100% - 80px);
    padding: 0 40px;
    min-height: 696px;
    .elInput {
      width: 100%;
    }
    .form-title {
      width: 100%;
      color: #3d3e4f;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 40px;
    }
    .form-share-title {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      span {
        color: #3d3e4f;
        font-size: 16px;
        font-weight: 400;
        &:first-child {
          color: #010334;
        }
      }
    }
    /deep/.el-form-item .el-select {
      width: 100%;
      height: 40px;
    }

    /deep/.el-form-item__label {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }
    /deep/.el-input--suffix .el-input__inner {
      padding-right: 30px;
    }
    /deep/.el-input--suffix .el-input__inner,
    /deep/.el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/.el-textarea__inner {
      height: 80px;
    }
    /deep/.el-switch__label.is-active {
      color: #010334;
    }
    /deep/.el-switch__label {
      color: #010334;
    }
    /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
      color: #003685;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #003685 !important;
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__inner:hover {
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__label {
      color: #010334 !important;
    }
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 130px;

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    .return {
      display: block;
      width: 137px;
      height: 40px;
      background: #c6000b;
      box-shadow: 0px 3px 10px rgba(198, 0, 11, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    .save {
      display: block;
      width: 137px;
      height: 40px;
      background: #e7c69c;
      box-shadow: 0px 3px 10px rgba(231, 198, 156, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
  /deep/.el-table {
    // margin-top: 14px;
  }
  /deep/.el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
  }
  /deep/.el-table th:first-child > .cell {
    border-left: transparent;
  }
  .table-handle.file {
    display: block;
    margin-left: 0;
  }
}
</style>
